<template>
  <div class="appDialog">
    <el-dialog
        :visible="true"
        width="35%"
        background-color='#fff'
        :show-close = 'false'
        center>
        <div class="contentClass5">
          <div>
            <i class="el-icon-warning"></i>
            <span>请注意</span>
            <p style="margin-top:15px;width:100%">您选中的行中存在【已打印过的发货单】的订单！</p>
            <p style="font-size:30px;font-weight:600;margin-top:10px">确认<span style="font-size:30px;margin:0;color:#49ab4a">重新</span>打印？</p>
          </div>
          <div class="contentFooter">
            <div @click="resumePrinting">没错，重新打印</div>
            <div @click="cancel">取消</div>
          </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "appDialog",
  data() {
    return {
      dialogShow5: true
    };
  },
  methods: {
    resumePrinting() {
      this.$emit("ok")
    },
    cancel(){
      this.$emit("cancel")
    },
  },
};
</script>

<style lang="less" scoped>
.appDialog {
  /deep/ .el-dialog {
    background-color: #fff;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 600;
      color: #2c2b3b;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  
  .contentClass5 {
    color: #000;
    padding-top: 20px;
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 15px;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      height: 53px;
      background-color: #f3f3f3;
      border-top: 1px solid #eaeaea;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 14px;
      // line-height: 25px;
      div {
        background-color: #ffdc98;
        border: 1px solid #fccd74;
        padding: 5px 18px;
        display: inline-block;
        color: #365064;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 15px;
      }
      div:nth-child(3) { 
        background-color: #f3f3f3;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-right: 15px;
      }
    }
  }
}
</style>